import { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../../header';
import './Mygroupsmembers.css';
import { Box, Grid, TextInput, Checkbox, Group , Button} from '@mantine/core';
import { useForm } from '@mantine/form';
import { DataTable } from 'mantine-datatable';
import { IconSearch, IconCalendar, IconArrowRight, IconPlus, IconPencil, IconTrash } from "@tabler/icons-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { Link,  useNavigate } from 'react-router-dom';
import Loader from '../Loader/Loader';
import dayjs from 'dayjs';
import userImage from '../../images/user.svg';



function Mygroupsmembers() {

  localStorage.setItem("DATE", (moment()).format('YYYY-MM-DD'));

const [loaded, dataLoading] = useState(false);

const user = localStorage.getItem("user").replace(/['"]+/g, '');
const role =localStorage.getItem("userrole");
const userName = localStorage.getItem("userName");
const memberGp = localStorage.getItem('userGroup');


const base_url = process.env.REACT_APP_BASE_URL;
const [filterdGroup, setFilteredGroup] = new useState([]);
const [selectedGroup, setSelectedGp] = useState([]);
const [gname, setgname] = useState();

function openGoupList(e){
  setgname(e)
  axios({ method: "POST", url:base_url+"/admin/get-all-member", data:{"group_name": [e]},  crossDomain: true, headers: {"Authorization" : `Bearer ${ user }`},}).then((response) => {
    if(response.data.response !== 'No data found'){setSelectedGp(response.data.response?.sort((a, b) => a.firstname > b.firstname ? 1 : -1,)); setFilteredGroup(selectedGroup);  dataLoading(true); 
  }
  })
}

const navigate = useNavigate();
const logout = (e) => {
  /* let keysToRemove = ["user", "DATE", "userrole", "userName", "email"];
  keysToRemove.forEach(k =>
    localStorage.removeItem(k)) */
    localStorage.clear();
  navigate('/Login');
}

/* const logoutforadmin = (e) => {
  let keysToRemove = ["user", "DATE", "userrole", "userName", "email"];
  keysToRemove.forEach(k =>
    localStorage.removeItem(k))
  navigate('/Adminlogin');
} */

const [searchActive, setSearchActive] = useState(false);

  function filterBySearch (event) {
      const query = (event.target.value).toLowerCase();
      var updatedList = [...selectedGroup];
      updatedList = selectedGroup.filter((item) => {
        return item?.firstname?.toLowerCase().indexOf(query) !== -1 || item?.lastname?.toLowerCase().indexOf(query) !== -1 || item?.mobile_number?.toLowerCase().indexOf(query) !== -1 || item?.email?.toLowerCase().indexOf(query) !== -1 || item?.country_code?.toLowerCase().indexOf(query) !== -1;
      });
      console.log(updatedList)
      setFilteredGroup(updatedList);
    };
  
  function blurSerach(e){
    if(e.target.value === '' || e.target.value ===null){setSearchActive(false)}
  }
  

  useEffect(() => {
    if(loaded === false){
        openGoupList(memberGp);   
    } 
    if(searchActive===false){
      openGoupList(memberGp);
      }  
    }, [loaded, memberGp ]);


  return (
    
    <div className="Dashboard" >
      <div className="page-wrpper"> 
      <Header />
      <div className='content-wrapper' >
        <div className='header-area'>
         
          <div className='header-top'>
            <div className='left-content' >
            <h1>Member List ({gname})</h1>
            </div>

            
            <div className='right-content'>
            { role ==='admin' ? <Link className='adminlogin' to={'/Adminlogin'} target="_blank">Database Admin</Link> : ''}
            <span className='current-user'><img alt="user" src={ userImage } />
           <span className='user-details'><span className='username'>{ userName }</span> <span className='userrole'>{ role }</span></span>
           
           <span className='user-popup'><button onClick={ logout }>Logout</button></span>
           </span>
            </div> 
          </div>
          
 
          
          <div className='header-left'>

           <Grid align="center" mb="md">
            <Grid.Col xs={8} sm={9}>
              <TextInput sx={{ flexBasis: '60%' }} onFocus={()=> setSearchActive(true)} onBlur={(value)=> blurSerach(value)} onChange={(value)=> filterBySearch(value)} placeholder="Search Members by First Name, Last Name, Conatct No. or Email" icon={<IconSearch size={16} />}    />
            </Grid.Col></Grid>
           
        </div> 

        <div className='header-right'>
        

      </div>

      </div>
      <div className='main-content groups-data'>
      
      

        <Box>
          <DataTable className="memers-table"
      noHeader
      records={filterdGroup}
      columns={[
        { accessor: 'firstname', cellsClassName: 'first-name', width: 90},
        {accessor: 'lastname', cellsClassName:'last-name', width: 90,},
        { accessor: 'mobile', render: ({ country_code, mobile_number }) => `${country_code} ${mobile_number}`, cellsClassName:'mobile', width: 150},
        {accessor: 'email', cellsClassName:'email', width: 200},
        { accessor: 'dob', render: ({ dob }) => moment(dob).format("DD MMM YYYY"),  cellsClassName:'dob', width: 120},
        {accessor: 'gender', cellsClassName:'gender', width: 80},
        {accessor: 'height', cellsClassName:'height', width: 75},
        {accessor: 'weight', cellsClassName:'weight', width: 75},
        {accessor: 'goal', cellsClassName:'goal', width: 70},
        {accessor: 'group_name', cellsClassName:'group-name', width: 120},        
        {accessor: 'created', render: ({ created_at }) => dayjs.unix(created_at).format("DD MMM YYYY"), cellsClassName:'onboarding',width: 100 },
        {accessor: 'uuid', cellsClassName:'user-link', width: 200,  render: ({ uuid,  dob, group_name}) => (<div className='cta-wrapper'><Link to={'/user?user='+uuid}  ><IconArrowRight /></Link></div> )},

      ]}

    />
        </Box>

  





{loaded === false ? <div className='global-loader'><Loader /></div> : '' }





</div>

    </div>

</div>


</div>

    
  );

}





export default Mygroupsmembers;
